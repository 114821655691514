import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function KatePopup() {
   const navigate = useNavigate();

   return (
      <div className="z-50 w-[100vw] h-[100vh] bg-[#ffffffb5] grid place-items-center">
         <div className="flex relative bg-[#F4FBFD] rounded-[40px] m-10 mt-0 overflow-hidden shadow-2xl justify-between">
            <div className="w-[800px]">
               <img src="other/more.png" className="mb-8" />
               <div className="mb-5"></div>
            </div>
            <motion.img
               src="exit.svg"
               whileTap={{ scale: 0.9 }}
               className="h-24 absolute top-10 right-10"
               onClick={() => navigate("/")}
            />
            <div className="w-[800px] flex items-center">
               <div className="max-w-[600px] mb-16">
                  <h2 className="text-2xl mt-16 font-bold">Více o Kate</h2>
                  <p className="mt-8 text-lg">
                     S Kate vyřešíte vše od odeslání platby, problému s kartou,
                     sjednání schůzky na pobočce a mnohem více.
                  </p>
                  <p className="mt-8 text-lg">
                     <b className="font-bold">Chcete se dozvědět více?</b>{" "}
                     Virtuální asistentku najdete v aplikaci ČSOB Smart.
                  </p>
                  <motion.button
                     className="px-16 text-lg mt-10 rounded-xl py-6 bg-[#0099CC]  text-white font-semibold"
                     whileTap={{ scale: 0.95 }}
                     onClick={() => navigate("/")}
                  >
                     Zpět do menu
                  </motion.button>
               </div>
            </div>
         </div>
      </div>
   );
}
