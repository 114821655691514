import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Navbar } from "./Navbar";
import { produce } from "immer";
import { uniqRandom } from "./pexeso/utils";
import ReactCardFlip from "components/CardFlip";
import { KateContext } from "components/Kate";
import { sleep } from "lib/tangle-js/functions";
import { tangleDevice } from "lib/utils/communication";
import { useAnalytics } from "analytics/AnalyticsContext";

let counter = 1;

const failVoices = [
   { mp3: "pexeso/pexeso1.mp3", msg: "Jejda! To není správná dvojice." },
   { mp3: "pexeso/pexeso2.mp3", msg: "Nevadí, zkuste to znovu..." },
   { mp3: "pexeso/pexeso3.mp3", msg: "To bylo o kousek!" },
   { mp3: "pexeso/pexeso4.mp3", msg: "Vyzkoušejte to ještě jednou." },
];

const successVoices = [
   { mp3: "pexeso/pexeso5.mp3", msg: "Skvělé!" },
   { mp3: "pexeso/pexeso6.mp3", msg: "Dobrá práce!" },
   { mp3: "pexeso/pexeso7.mp3", msg: "Tohle se povedlo!" },
   { mp3: "pexeso/pexeso8.mp3", msg: "Výborně, jen tak dál!" },
];

const WinVoices = [
   { mp3: "", msg: "Gratuluji k výhře!" },
   // {
   //   mp3: "",
   //   msg: "Pexeso se vám podařilo dokončit během XY tahů. To je skvělý výkon!",
   // },
];

function generateBoard(flipped = false) {
   // array with 8 pairs of numbers
   // let numbers = Array.from({ length: 8 }, (_, i) => i + 1);
   // const numbers2 = Array.from({ length: 8 }, (_, i) => i + 1);
   let numbers = uniqRandom(8, 1, 29);
   numbers = [...numbers, ...numbers];
   // shuffle array and concat it with itself
   const shuffled = numbers.sort(() => Math.random() - 0.5);

   let cards = [];
   for (let i = 0; i < 16; i++) {
      cards.push({
         id: i,
         flipped: !!flipped,
         image: `card/${shuffled[i]}.png`,
      });
   }
   // console.log(shuffled);

   return cards;
}

export default function Pexeso() {
   const [tah, setTah] = React.useState(0);
   const [cards, setCards] = React.useState(() => generateBoard());
   const [flippedCardIndex, setFlippedCardIndex] = useState<number | null>(
      null
   );
   const [blockFlip, setBlockFlip] = useState(false);
   const [won, setWon] = useState(false);

   const revealedCount = React.useMemo(() => {
      const revealedCount = cards.filter((card) => card.flipped).length;

      revealedCount === 16 ? setWon(true) : setWon(false);

      return revealedCount;
   }, [cards]);

   useEffect(() => {
      if (won) {
         // say({ msg: "Vyhráli jste! Hrozně moc gratuluji.", mp3: "" });
         sayRandom(WinVoices);
         tangleDevice.emitEvent("winpe");
         finishTrackingCurrent(Math.floor(tah / 2));
      }
   }, [won, tah]);

   async function resetGame(flipped = false) {
      setTah(0);
      // resetGame
      setBlockFlip(true);
      await say({ msg: "Pojďme na to!", mp3: "" });
      tangleDevice.emitEvent("pexes");

      // setCards(cards => cards.map(c => ({...c,flipped:false})))
      // await sleep(1000);
      setCards(() => generateBoard(flipped));
      setBlockFlip(false);
   }

   function flipCard(index: number) {
      setCards(
         produce((draft) => {
            if (!blockFlip) {
               if (typeof flippedCardIndex !== "number") {
                  draft[index].flipped = true;
                  setFlippedCardIndex(index);
               } else {
                  draft[index].flipped = true;
                  setFlippedCardIndex(null);

                  if (draft[index].image === draft[flippedCardIndex].image) {
                     // say({ mp3: "", msg: "Dobře ty!!!" });
                     sayRandom(successVoices);
                  } else {
                     setBlockFlip(true);

                     // say({ mp3: "", msg: "Nic... zkus to znova!" }).then(() => {});
                     // sayRandom(failVoices).then(() => {
                     //   setCards(
                     //     produce((draft) => {
                     //       setBlockFlip(false);

                     //       draft[index].flipped = false;
                     //       draft[flippedCardIndex].flipped = false;
                     //     })
                     //   );
                     // })
                     if (counter++ % 3 === 0) {
                        sayRandom(failVoices);
                     }

                     setTimeout(() => {
                        // setCards((cards) =>
                        //   cards.map((card) => ({ ...card, flipped: false }))
                        // );
                        setCards(
                           produce((draft) => {
                              setBlockFlip(false);

                              draft[index].flipped = false;
                              draft[flippedCardIndex].flipped = false;
                           })
                        );
                     }, 1500);
                  }
               }
            }
         })
      );

      // if(matchedCards) {
      setTah((tah) => tah + 1);
      // }
   }

   const { queue, say, sayRandom, stop, activate } = useContext(KateContext);

   const { startTracking, finishTrackingCurrent } = useAnalytics();

   useEffect(() => {
      activate(true);
      tangleDevice.emitEvent("pexes");

      startTracking("story", "pexeso");
   }, []);

   function Overlay({ resetGame }: { resetGame: Function }) {
      const navigate = useNavigate();

      useEffect(() => {}, []);

      return (
         <div className="relative">
            <div className="absolute mt-8 z-10">
               <div className="h-[780px] w-[900px]  -ml-8 flex flex-col  justify-center items-center text-center bg-[#f4fbfde9] z-50">
                  <h2 className="text-xl font-bold mb-2">Gratuluji k výhře</h2>
                  <div className="font-semibold text-lg opacity-50 mt-2">
                     Pexeso se vám podařilo dokončit během{" "}
                     <strong>{Math.floor(tah / 2)} tahů</strong>. <br />
                     To je skvělý výkon!
                  </div>
                  <div className="flex justify-around gap-8 mt-8">
                     <motion.button
                        onClick={() => navigate("/")}
                        whileTap={{ scale: 0.96 }}
                        className="rounded-xl border px-10 py-6 font-semibold text-lg border-[#0099CC] bg-[white] text-[#0099CC] transition hover:bg-[#CFECF4]"
                     >
                        Zpět do menu
                     </motion.button>
                     <motion.button
                        onClick={() => resetGame()}
                        whileTap={{ scale: 0.98 }}
                        className="rounded-xl border text-white border-[#0099CC] transition bg-[#0099CC] hover:bg-[#0086B2] px-10 py-6 font-semibold text-lg"
                     >
                        Nová hra
                     </motion.button>
                  </div>
               </div>
            </div>
         </div>
      );
   }

   return (
      <div className="w-[100vw] h-[100vh]">
         <Navbar part={"Kate"} title={"PEXESO"} />
         <div className="flex bg-[#F4FBFD] rounded-2xl m-10 mt-0 justify-between">
            <div className="w-[900px]">
               <div className="p-24">
                  <h2 className="text-2xl font-extrabold">
                     Tah č. {Math.floor(tah / 2)}
                  </h2>
                  <div className="font-semibold text-lg mb-5 opacity-50 mt-2 text-black">
                     Otočeno {Math.floor(revealedCount / 2)}/8 párů
                  </div>
               </div>
            </div>
            <div className="w-[850px] ">
               {revealedCount === 16 && <Overlay resetGame={resetGame} />}
               <div className="grid grid-cols-4 gap-5 m-10">
                  {cards.map((card, index) => (
                     <PexesoCard
                        index={index}
                        {...card}
                        key={index}
                        flipCard={flipCard}
                     />
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
}

function PexesoCard({
   index,
   image,
   flipped,
   flipCard,
}: {
   index: number;
   id: number;
   image: string;
   flipped: boolean;
   flipCard: (index: number) => void;
}) {
   return (
      <motion.div
         className={flipped ? "pointer-events-none" : ""}
         whileTap={{ scale: 0.96, rotateX: "-10deg", rotateY: "-10deg" }}
      >
         <ReactCardFlip
            isFlipped={!flipped}
            children={[
               <motion.div
                  className="w-full bg-white rounded-2xl overflow-hidden"
                  style={{
                     filter:
                        "drop-shadow(0px 11.5024px 30.673px rgba(0, 77, 153, 0.11))",
                     aspectRatio: "1 / 1",
                  }}
                  onClick={() => flipCard(index)}
               >
                  <img src={image} className="w-full p-2" alt="" />
               </motion.div>,
               <motion.div
                  className="w-full bg-white rounded-2xl overflow-hidden"
                  style={{
                     filter:
                        "drop-shadow(0px 11.5024px 30.673px rgba(0, 77, 153, 0.11))",
                     aspectRatio: "1 / 1",
                  }}
                  onClick={() => flipCard(index)}
               >
                  <img src="card/back.png" className="w-full " />
               </motion.div>,
            ]}
         />
      </motion.div>
   );
}
