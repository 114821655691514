import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

export function Navbar({ part, title }: { part: string; title: string }) {
   const navigate = useNavigate();

   return (
      <nav className="w-full flex items-center bg-white justify-between p-10 py-8">
         <div className="w-64 ">
            <motion.div
               onClick={() => navigate("/")}
               animate={{ background: "#F4FBFD" }}
               whileTap={{ scale: 0.9, background: "#CFECF4" }}
               className="w-28 h-[80px] rounded-xl flex justify-center items-center transition  p-4"
            >
               <img src="back.svg" className="scale-150"></img>
            </motion.div>
         </div>

         <div className="title text-lg flex items-center">
            <div className="font-bold tracking-tight">{part}</div>
            <div className="h-10 w-[1px] mx-3 bg-[#C2DDE9] font-bold"></div>{" "}
            <span className="">{title}</span>
         </div>
         <div className="next w-64 flex justify-end">
            <img src="CSOB.png" className="h-16" />
         </div>
      </nav>
   );
}
