import { atom, useAtom } from "jotai";
import { tangleDevice } from "lib/utils/communication";
import { useSpectodaSound } from "lib/utils/utils";
import React, { useEffect, useContext } from "react";
import { TangleConnection } from "TangleConnectionContext";
import CSOB from "../components/CSOB";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { KateContext } from "components/Kate";
import { useAnalytics } from "analytics/AnalyticsContext";
import { spectodaEventSource } from "lib/utils/bridgecalls";

// create jotai atom which stores the current animation
export const activeAtom = atom("off");

// throtte function function
function throttle(fn: Function, limit: number) {
  let inThrottle: boolean;
  return function (event: any, say: Function, startUsageTracking: Function) {
    const args = arguments;
    if (!inThrottle) {
      fn(event, say, startUsageTracking);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

function kateInteractionHandle(event: any, say: Function, startUsageTracking: Function) {
  if ("_pir_" === event.label) {
    say({
      mp3: "story/vitejte.mp3",
      msg: "Vítejte, já jsem Kate a chtěla bych vám ukázat co umím.",
    });
    startUsageTracking("movement");
  }
}
const iteractionThrottler = throttle(kateInteractionHandle, 60000);

// throttle kateInteraction function

export default function Home() {
  const navigate = useNavigate();
  const { activate, say } = useContext(KateContext);
  const { startTracking, finishTrackingCurrent } = useAnalytics();

  function startUsageTracking(type: string) {
    startTracking("usage", type);
  }

  useEffect(() => {
    //  let can = false;
    finishTrackingCurrent();

    setTimeout(() => {
      // can = true;
    }, 10000);
    const clearEventHandler = spectodaEventSource.on("event", (event: any) => {
      // kateInteractionHandle()
      // throttle, kateInteractionHanle function
      // iteractionThrottler(kateInteractionHandle)
      if ("_pir_" === event.label) {
        console.log("event", event);
      }
      iteractionThrottler(event, say, startUsageTracking);
    });
    return clearEventHandler;
  }, []);

  // const [active, setActive] = useAtom(activeAtom);
  // const [microphoneSensitivity, setMicrophoneSensitivity] =
  //   useAtom(micSensitivityAtom);
  // const { start, stop, spectodaSound } = useSpectodaSound();
  const { connectionStatus, connect } = useContext(TangleConnection);

  // pick random unique 8 numbers from 1-20
  // const randomNumbers = Array.from(
  //   { length: 8 },
  //   () => Math.floor(Math.random() * 20) + 1
  // ).filter((v, i, a) => a.indexOf(v) === i);

  useEffect(() => {
    activate(false);
    tangleDevice.emitEvent("home");
  }, []);

  const cards = [
    {
      title: "Student",
      name: "Robert",
      image: "home/robert.svg",
      route: "student",
    },
    {
      title: "Zubař",
      name: "David",
      image: "home/david.svg",
      route: "zubar",
    },
    {
      title: "Maminka",
      name: "Alena",
      image: "home/alena.svg",
      route: "maminka",
    },
    {
      title: "Zpěvačka",
      name: "Julie",
      image: "home/julie.svg",
      route: "zpevacka",
    },
  ];

  return (
    <div
      className="w-[100vw] h-[100vh] flex"
      style={{
        background: "url(home/bg.svg) 1980px 1080px",
        backgroundPosition: "top left",
      }}
      onClick={() => startUsageTracking("click")}
    >
      <div className="w-[1080px] max-w-[1080px] p-[120px] pr-0">
        <h1 className="font-extrabold text-3xl flex text-white">
          <motion.img
            whileTap={{ scale: 1.1 }}
            src="home/kate.svg"
            alt=""
            className="mr-8 w-[90px] "
            // @ts-ignore
            // onDoubleClick={() => location.reload(true)}
          />
          {/* <button onClick={() => (location.href = "https://3000-spectoda-csob-k8aybn1du1w.ws-eu81.gitpod.io/")}>DEBUG</button> */}
          Seznamte se s Kate
        </h1>
        <div className="mt-[100px]">
          <h2 className="text-2xl font-extrabold ">Vyberte si postavu</h2>
          <p className="font-semibold text-lg ">Zajímá vás více? Pojďte se mnou na výlet!</p>
        </div>
        <div className="mt-12">
          <div className="grid grid-cols-2 gap-10 max-w-[690px]">
            {cards.map((card, key) => (
              <ProfileCard key={key} {...card} onClick={() => navigate("story/" + card.route)} />
            ))}
          </div>
        </div>
        <div className="mt-[140px]">
          <motion.img
            whileTap={{ scale: 1.1 }}
            onClick={async () => {
              navigate("/kate");
            }}
            src="home/quest.svg"
            className="w-[300px]"
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-col w-[840px] items-end">
        {/* <button onClick={() => }>Connect</button> */}

        <motion.img
          // onClick={() => connect()}
          whileTap={{ rotate: "180deg", scale: 1.1 }}
          src="home/leon.png"
          alt=""
          className="w-[600px]"
        />

        <div className="relative mr-16 flex flex-col items-end">
          <h2 className="text-2xl mt-[150px] font-extrabold text-right">Něco pro menší</h2>
          <p className="font-semibold text-lg text-right mt-2">Zahrajte si pexeso!</p>

          <div className="relative mt-[60px]">
            <img src="home/dinocard2.png" alt="" className="absolute -top-[52px] -left-[90px] w-[150px] z-0" />
            <img src="home/dinocard1.png" alt="" className="absolute top-8 -left-16 w-[120px] z-20" />

            <motion.button whileTap={{ scale: 0.96 }} onClick={() => navigate("pexeso")} className="absolute rounded-2xl text-white px-[60px] py-[40px] font-bold text-xl z-10 bg-[#003366] transition hover:bg-[#002447]">
              Hrát pexeso
            </motion.button>

            <button className="opacity-0 rounded-2xl bg-[#003366] text-white px-[60px] py-[40px] font-bold text-xl z-10">Hrát pexeso</button>
          </div>

          {/* <motion.img
            onClick={() => navigate("pexeso")}
            className="w-96 max-w-none absolute -right-12"
            whileTap={{ scale: 0.96 }}
            src="home/play.svg"
            alt=""
          /> */}
        </div>
      </div>
    </div>
  );
}

function ProfileCard(card: any) {
  return (
    <motion.div whileTap={{ scale: 0.96 }} onClick={card.onClick}>
      <motion.div animate={{ background: "white" }} whileTap={{ background: "#CFECF4" }} className="flex w-[324px] h-[132px] rounded-xl shadow-[0px_24px_24px_rgba(2, 77, 94, 0.1)] py-4 px-6 items-center transition ">
        <img src={card.image} className={card.name == "David" ? "scale-110" : "scale-75"} alt="" />
        <div className="ml-4 flex flex-col justify-center">
          <h3 className="text-[22px] leading-[33px] mt-2 font-semibold">{card.title}</h3>
          <p className="text-xl font-[700] -mt-2 tracking-tight">{card.name}</p>
        </div>
      </motion.div>
    </motion.div>
  );
}
