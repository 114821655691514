import { AnimatePresence } from "framer-motion";
import Layout from "layout/Layout";
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Pexeso from "pages/Pexeso";
import { motion } from "framer-motion";
import Kate from "components/Kate";
import Home from "pages/Home";
import Story, { stories } from "pages/Story";
import LeaverBuster from "components/LeaverBuster";
import KatePopup from "components/KatePopup";

export default function Routing() {
   const location = useLocation();

   return (
      <LeaverBuster>
         <Layout>
            {/* <Header /> */}
            <AnimatePresence exitBeforeEnter>
               <Routes location={location} key={location.pathname}>
                  <Route
                     path="/"
                     element={
                        <AnimationWrapper>
                           <Home />
                        </AnimationWrapper>
                     }
                  />
                  <Route
                     path="/pexeso"
                     element={
                        <AnimationWrapper>
                           <Pexeso />
                        </AnimationWrapper>
                     }
                  />
                  <Route
                     path="/kate"
                     element={
                        <AnimationWrapper>
                           <KatePopup />
                        </AnimationWrapper>
                     }
                  />
                  <Route path="/story/:character" element={<Story />} />
                  {/* <Route path="/mp3" element={<MP3 />} /> */}
                  {/* <Route path="/mp3/default" element={<MP3Default />} /> */}
                  {/* <Route path="kate" element={<Kate />} /> */}
                  {/* <Route path="beyond" element={<Beyond />} />
          <Route path="distribution" element={<Distribution />} />
          <Route path="responsible" element={<Responsible />} /> */}
               </Routes>
            </AnimatePresence>
            <Kate />
            {/* <AssetPrefetcher /> */}
         </Layout>
      </LeaverBuster>
   );
}

export const pageMotion = {
   initial: { opacity: 0, x: 0, transition: { duration: 0.5 } },
   animate: { opacity: 1, y: 0, x: 0, transition: { duration: 0.5 } },
   exit: { opacity: 0, x: 0, transition: { duration: 0.5 } },
};

function AnimationWrapper({ children }: React.PropsWithChildren<any>) {
   return (
      <motion.div
         initial="initial"
         animate="animate"
         exit="exit"
         variants={pageMotion}
      >
         {children}
      </motion.div>
   );
}

function AssetPrefetcher() {
   return (
      <div className="prefetcher invisible">
         {Object.keys(stories).map((key) => {
            // @ts-ignore
            const story = stories[key] as typeof stories["student"];

            return story.story.map((s, i) => {
               return (
                  <React.Fragment key={i}>
                     {s.image && <link rel="prefetch" href={s.image} />}
                     {/* @ts-ignore */}
                     {s.mp3 && <link rel="prefetch" href={s.mp3} />}
                     {/* @ts-ignore */}
                     {s.chat?.src && <link rel="prefetch" href={s.chat.src} />}
                     {/* @ts-ignore */}
                     <RecursivePrefetcher options={s.options} />
                  </React.Fragment>
               );
            });
         })}
         {new Array(29).fill(1).map((_, i) => (
            <link rel="prefetch" href={`card/${i + 1}.png`} key={i} />
         ))}
      </div>
   );
}

function RecursivePrefetcher({ options }: typeof stories.student.story[3]) {
   // @ts-ignore
   return options.map((o, i) =>
      o.type === "story" ? (
         <React.Fragment key={i}>
            {" "}
            {/* @ts-ignore */}
            {o.image && <link rel="prefetch" src={o.story.image} />}
            {/* @ts-ignore */}
            {o.story.mp3 && <link rel="prefetch" href={o.story.mp3} />}
            {/* @ts-ignore */}
            {o.chat?.src && <link rel="prefetch" href={o.chat.src} />}
            {/* @ts-ignore */}
            <RecursivePrefetcher options={o.story.options} />
         </React.Fragment>
      ) : (
         <></>
      )
   );
}
