import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navbar } from "./Navbar";
import { student } from "./stories/student";
import { zubar } from "./stories/zubar";
import { maminka } from "./stories/maminka";
import { zpevacka } from "./stories/zpevacka";
import { AnimatePresence, motion } from "framer-motion";
import { KateContext } from "components/Kate";
import { pageMotion } from "Routing";
import ReactMarkdown from "react-markdown";

import remarkGfm from "remark-gfm";
import { tangleDevice } from "lib/utils/communication";
import StoryOverlay from "./StoryOverlay";
import { useAnalytics } from "analytics/AnalyticsContext";

export const stories = {
   student,
   zubar,
   maminka,
   zpevacka,
};

let story = {} as typeof stories["student"];

export default function Story() {
   const { character } = useParams() as {
      character: "student";
   };
   const { startTracking, finishTrackingCurrent } = useAnalytics();

   useEffect(() => {
      startTracking("story", character);
   }, []);

   // const [optionIndex, setOptionIndex] = useState(0);
   // const [storyIndex, setStoryIndex] = useState(0);
   const [chat, setChat] = useState<any>({});
   const [mainIndex, setMainIndex] = useState(0);
   const [previousPart, setPreviousPart] = useState(
      story.story && story.story[mainIndex]
   );
   const [previousPartIndex, setPreviousPartIndex] = useState(0);
   const [currentPart, setCurrentPart] = useState<
      typeof stories["student"]["story"][0]
      // @ts-ignore
   >({});
   const navigate = useNavigate();

   function doAction(option: any, index: number) {
      if (option.type === "story") {
         option.clicked = true;

         setCurrentPart(option.story);
         setPreviousPart(currentPart);
      } else if (option.type === "next" || !option.type) {
         option.clicked = true;

         setCurrentPart(story.story[mainIndex + 1]);
         setMainIndex(mainIndex + 1);
         setPreviousPartIndex(mainIndex);
      } else if (option.type === "exit") {
         navigate("/");
      } else if (option.type === "back") {
         setCurrentPart(previousPart);
         // setMainIndex(previousPartIndex);
      } else if (option.type === "back-main") {
         // setCurrentPart(option.story);
         // setMainIndex(index);
         // setOptionIndex(0);
         setMainIndex(mainIndex);
         setCurrentPart({ ...story.story[mainIndex] });

         try {
            // setCurrentPart(previousPart);
            // setStoryIndex(storyIndex - 1);
            // console.log(story.story[mainIndex].options[optionIndex]);
            // @ts-ignore
            // setCurrentPart(story.story[mainIndex].options[optionIndex].story[storyIndex - 1]);
         } catch {}
      } else if (option.type === "back-slide") {
         setMainIndex(mainIndex - 1);
         setCurrentPart({ ...story.story[mainIndex - 1] });
      } else if (option.type === "next-slide") {
         setMainIndex(mainIndex + 1);
         setCurrentPart({ ...story.story[mainIndex + 1] });
      } else if (option.type === "kate") {
         navigate("/kate");
      }
   }
   const { queue, say, stop, activate } = useContext(KateContext);

   // useEffect(() => {

   // }, []);

   // const story = stories[character];
   useEffect(() => {
      // say({ mp3: "", msg: "Jdeme na to!" });
      activate(true);

      story = JSON.parse(JSON.stringify(stories[character]));
      setPreviousPart(story.story && story.story[mainIndex]);
      setCurrentPart(story.story && story.story[mainIndex]);

      tangleDevice.emitEvent("stor" + story.storyNumber);
   }, []);

   useEffect(() => {
      // @ts-ignore
      let t;
      if (currentPart && currentPart.content) {
         t = setTimeout(() => {
            // @ts-ignore
            if (currentPart && currentPart.kateText) {
               // @ts-ignore
               console.log("Say", currentPart.mp3);
               // @ts-ignore
               say({
                  // @ts-ignore
                  mp3: currentPart.mp3,
                  msg: currentPart.kateText,

                  // @ts-ignore
                  // delay: currentPart.delay,
               });
            }
         }, (currentPart && currentPart.delay) || 0);

         if (currentPart?.options?.findIndex((o) => o.type === "exit") >= 0) {
            tangleDevice.emitEvent("wins" + story.storyNumber);
         }
      }
      // @ts-ignore
      return () => clearTimeout(t);
   }, [currentPart]);

   return (
      <div className="w-[100vw] h-[100vh] max-w-full">
         <Navbar title={"Kate příběh"} part={story.title || "Připravujeme"} />
         <AnimatePresence>
            {Object.keys(story).length === 0 || !currentPart.content ? (
               <div className="m-5">Načítání...</div>
            ) : (
               <motion.div
                  className="m-8 opacity-100"
                  key={currentPart.content}
                  // @ts-ignore
                  initial={{ opacity: 0, x: 0, transition: { duration: 0.3 } }}
                  animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
                  exit={{ opacity: 0, x: 0, transition: { duration: 0.3 } }}

                  // initial="initial"
                  // animate="animate"
                  // exit="exit"
                  // variants={pageMotion}
               >
                  <div
                     className={
                        "flex bg-[#F4FBFD] rounded-2xl h-[80vh] max-w-full justify-between shadow" +
                        // @ts-ignore
                        (currentPart.reverse === true ? "flex-row-reverse" : "")
                     }
                  >
                     <div
                        className="w-[1000px] p-20 "
                        style={
                           // @ts-ignore
                           currentPart.reverse && {
                              position: "relative",
                              left: "-100px",
                           }
                        }
                     >
                        {/* <div className="font-semibold text-lg mb-5">Rozhodl jste se zvednout telefon. (Sample)</div> */}
                        {/* @ts-ignore */}
                        <div className="text-content text-[36px] leading-relaxed mb-5 markdown-styles">
                           <ReactMarkdown
                              children={currentPart.content}
                              remarkPlugins={[remarkGfm]}
                           />
                        </div>
                        <div
                           className={`other-content ${
                              currentPart.options.length > 1 && "mt-8"
                           }`}
                        >
                           <div className="font-semibold text-lg mb-5 text-[#0099CC]">
                              {/* @ts-ignore */}
                              {currentPart.optionsTitle}
                           </div>
                           <div className="mt-14">
                              {currentPart.options.map((o, index) =>
                                 currentPart.options.length > 1 ? (
                                    <motion.div
                                       whileTap={{ scale: 0.96 }}
                                       key={index}
                                       className={
                                          "rounded-3xl mt-5 p-8 px-10 text-lg font-medium text-white border max-w-[750px] border-[#0099CC] transition bg-[#0099CC] hover:bg-[#0086B2]" +
                                          // @ts-ignore
                                          (o?.clicked
                                             ? " opacity-50 pointer-events-none"
                                             : "")
                                       }
                                       onClick={() => doAction(o, index)}
                                    >
                                       {o.text}
                                    </motion.div>
                                 ) : (
                                    <motion.div
                                       key={index}
                                       whileTap={{ scale: 0.96 }}
                                       className="rounded-3xl p-8 px-10 text-lg font-semibold inline-block border  bg-[#0099CC] text-white transition hover:bg-[#CFECF4]"
                                       onClick={() => doAction(o, index)}
                                    >
                                       {o.text}
                                    </motion.div>
                                 )
                              )}
                           </div>
                        </div>
                     </div>
                     <div className="w-auto flex flex-col items-end">
                        {currentPart.image && !currentPart.chat && (
                           <img
                              className="overflow-hidden rounded-tr-2xl rounded-br-2xl ml-4 h-full"
                              src={currentPart.image}
                              alt=""
                           />
                        )}
                        {currentPart.chat && (
                           <StoryOverlay
                              doAction={doAction}
                              chat={currentPart.chat}
                              option={currentPart.options[0]}
                           />
                        )}
                     </div>
                  </div>
               </motion.div>
            )}
         </AnimatePresence>

         <div className=""></div>
         {/* <pre>{JSON.stringify(currentPart, null, 2)}</pre> */}
      </div>
   );
}
