import { motion } from "framer-motion";
import { nanoevents } from "components/LeaverBuster";
import React, { useEffect, useRef } from "react";
import { TangleMsgBox } from "lib/webcomponents/dialog-component";
import { SpectodaSound } from "lib/tangle-js/SpectodaSound";
import { tangleDevice } from "lib/utils/communication";
import { emitEvent } from "lib/utils/bridgecalls";

const spectodaSound = new SpectodaSound();
let lastValue = 1;

function assignLoudnessListener(spectodaSound: SpectodaSound) {
  return spectodaSound.on("loudness", (value: number) => {
    if (lastValue === 0 && value === 0) {
    } else {
      emitEvent({
        type: "percentage",
        value: value,
        label: "sound",
      });
    }
    lastValue = value;
  });
}

export default function StoryOverlay({ chat, doAction, option }: any) {
  //   const kateVideo = useRef<HTMLVideoElement>();
  useEffect(() => {
    if (chat.src) {
      setTimeout(() => {
        const video = document.querySelector("#kateVideo") as HTMLVideoElement;

        let playListener = () => {
          video.removeEventListener("playing", playListener);
          // @ts-ignore
          spectodaSound.connect(video.captureStream()).then(() => {
            spectodaSound.start();
          });
          assignLoudnessListener(spectodaSound);
        };

        video.addEventListener("playing", playListener);
        video.play();
        video.onended = () => {
          spectodaSound.stop();
        };
      }, 100);
    }

    return () => {
      spectodaSound.stop();
    };
  }, [chat]);
  if (!chat?.src) {
    return <React.Fragment />;
  }
  return (
    <div className="">
      <img src="./kouknete2.png" alt="" className="fixed z-10 bottom-[120px] right-[560px] w-[350px]" />
      {/* TODO do caching for it */}
      <div className="absolute right-[92px] top-[140px] z-10">
        <img src="./Mockup.png" alt="" className="relative w-[460px] z-20 top-[4px]" />
        <video
          id="kateVideo"
          //  ref={kateVideo}
          src={chat.src}
          //  controlsList={}

          className="absolute top-[52px] left-[13px] w-[444px] mb-3 shadow-2xl"
          onEnded={() => {
            console.log("Video ended");
            nanoevents.emit("reset_timer");
            setTimeout(() => {
              doAction(option);
            }, 500);
          }}
        ></video>
      </div>
    </div>
  );
}
